@import '~assets/scss/variables.scss';

.card {
    min-height: 460px;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
}

.titleWrap {
    display: flex;
    margin-bottom: 32px;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-weight: 700;
    // margin-bottom: 40px;
}

.chart {
    height: 350px;
}

.legend {
    margin-top: 24px;
    margin-left: 4px;
}

.select.select {
    width: 140px;
    :global {
        .select__control {
            background-color: #f5f6fb;
        }
    }
}

.is-empty {
    transition: all 0.2s ease-in-out;
    opacity: 0.3;
    background-color: $colorBlueGrey;
}

.empty-label {
    margin: auto;
    z-index: 100;
}
