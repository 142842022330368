@import '~assets/scss/variables.scss';

.container {
    background-color: $colorPaleGrey;
    border-radius: 8px;
    :global(.rdrCalendarWrapper) {
        background-color: $colorPaleGrey;
        border-radius: 8px;
        width: 532px;
    }
    :global(.rdrDateRangePickerWrapper) {
        overflow: hidden;
        border-radius: 8px;
        padding: 4px;
    }
    :global(.rdrDefinedRangesWrapper) {
        background-color: $colorPaleGrey;
        width: 192px;
        // border-radius: 8px 0px 0px 8px;
    }
    // :global(.rdrStaticRanges) {
    //     border-radius: 8px 0px 0px 0px;
    // }
    :global(.rdrStaticRange) {
        &:first-child {
        }
    }
    :global(.rdrStaticRangeLabel) {
        background-color: $colorPaleGrey;
        text-align: center;
    }
    :global(.rdrMonthPicker),
    :global(.rdrYearPicker) {
        select {
            font-weight: bold;
            font-family: 'GT Walsheim Pro Bold', sans-serif !important;
        }
    }
    // :global(.rdrDateDisplayWrapper) {
    //     border-radius: 0px 20px 0px 0px;
    // }
}
