@import '~assets/scss/shared.scss';
@import '~assets/scss/variables.scss';

.root {
    position: relative;
}

.input {
    font-size: 16px;
    width: 100%;
    outline: none;
    height: 40px;
    background-color: #fff;
    border-radius: 8px;
    border: none;
    padding: 12px 20px;
    font-family: GTWalsheimPro;
    font-size: 14px;
    line-height: 14px;
    box-sizing: border-box;
    background-color: $colorPaleGrey;
    &.have-before {
        padding-left: 56px;
    }
    &:disabled {
        cursor: not-allowed;
    }
}

.before {
    position: absolute;
    top: 13px;
    left: 20px;
    font-size: 14px;
}
