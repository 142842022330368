@import "~assets/scss/variables.scss";

.title {
  font-family: "GTWalsheimPro", sans-serif;
  font-weight: 700;
}

h2.title {
  font-size: 32px;
  line-height: 32px;
  color: $colorRoot;
}

h3.title {
  font-size: 24px;
  line-height: 32px;
  color: $colorRoot;
}

h4.title {
  font-size: 16px;
  line-height: 16px;
  color: $colorCharcoalGrey;
}

h5.title {
  font-size: 14px;
  line-height: 16px;
}

.text {
  font-family: "GTWalsheimPro", sans-serif;
  font-size: 16px;
  line-height: 24px;
}
