@import '~assets/scss/variables.scss';

.section {
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    // column-gap: 20px;
    // margin-top: 20px;
}

.orders {
    min-height: 468px;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 32px 32px 12px 32px;
    margin-bottom: 16px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .button {
        span {
            display: flex;
        }
        // width: 119px;
        padding-left: 16px;
        padding-right: 16px;
        height: 40px;
        font-size: 12px;
        line-height: 16px;
        background-color: $colorPaleGrey;
        color: $colorCharcoalGrey !important;
        font-family: 'GT Walsheim Pro Bold', sans-serif;
        font-weight: 700;

        &::before {
            content: none;
        }
    }
}

.arrow {
    transition: all 0.25s ease-in-out;
    transform: rotate(90deg);
    margin-left: 12px;
    font-size: 10px;
    color: $colorBlueGrey;
}

.order {
    font-weight: bold !important;
    font-family: 'GT Walsheim Pro Bold', sans-serif;
    color: $colorRoot;
    // max-width: 280px;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
}

.table {
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    tbody {
        tr {
            cursor: pointer;
            &:hover {
                background-color: $colorPaleGrey;
            }
        }
    }
}

.slide {
    &:hover {
        .arrow {
            transform: rotate(-90deg);
        }
    }
}

.empty {
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400 !important;
}
