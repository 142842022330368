@import '~assets/scss/variables.scss';

.root {
    display: flex;
    position: relative;
}

.progress {
    width: 60px;
    height: 60px;
    margin-left: 11px;
    position: absolute;
    left: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 300ms ease-out;
    &:first-child {
        margin-left: 0;
    }
}

.item {
    width: 60px;
    cursor: pointer;
    height: 60px;
    background-color: #c1cbde;
    border-radius: 50%;
    margin-left: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    opacity: 0.4;
    position: relative;
    transition: background-color 300ms ease-out;
    &.is-current {
        opacity: 1;
    }
    &.in-progress {
        background-color: #fff;
        .progress {
            opacity: 1;
            visibility: visible;
        }
        .text {
            color: $immoPrimary;
        }
    }
    &:first-child {
        margin-left: 0;
    }
}

.text {
    position: relative;
    z-index: 3;
    transition: color 300ms ease-out;
}

.done {
    position: absolute;
    background-color: $immoPrimary;
    width: 16px;
    height: 16px;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 7px;
    border-radius: 50%;
    opacity: 0;
    visibility: hidden;
    transition: all 400ms ease-in-out;
    &.is-finished {
        opacity: 1;
        visibility: visible;
    }
}
