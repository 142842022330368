.content {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    line-height: 16px;
    input,
    textarea {
        font-size: inherit;
        line-height: inherit;
    }
}

.cell {
    flex: 1;
    max-width: calc(50% - 25px);
}

.description {
    color: #2b303a;
    padding-top: 20px;
}

.buttons {
    text-align: right;
    button + button {
        margin-left: 12px;
    }
}
