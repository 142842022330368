@import '~assets/scss/variables.scss';

.info {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
    margin-top: 40px;
    margin-bottom: 20px;
}

.buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.balance {
    font-size: 14px !important;
    line-height: 16px !important;
    font-family: 'GTWalsheimPro', sans-serif !important;
    color: $colorCloudyBlueTwo;
    // white-space: nowrap;
    // overflow: visible;
    .is-positive {
        color: $lightGreen;
    }
    .is-negative {
        color: $lightRed;
    }
    // &.refund {
    //     text-indent: 10px;
    // }
}

.hidden {
    opacity: 0;
}
