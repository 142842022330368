@import '~assets/scss/variables.scss';
@import '~assets/scss/shared.scss';

.root {
    background-color: $colorLightishBlue;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 52px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    color: #fff;
    @extend %shared-hover-transition;
    &.is-open {
        opacity: 1;
        visibility: visible;
    }
}

.icon {
    position: absolute;
    top: 50%;
    right: 30px;
    margin-top: -5px;
    cursor: pointer;
}

.close {
    width: 12px;
    height: 12px;
}
.close:hover {
    opacity: 1;
}
.close:before,
.close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 13px;
    width: 2px;
    background-color: white;
}
.close:before {
    transform: rotate(45deg);
}
.close:after {
    transform: rotate(-45deg);
}
