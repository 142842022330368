@import '~assets/scss/variables.scss';

.grid {
    display: grid;
    column-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 20px;
}

.card {
    h2 {
        font-size: 24px;
    }
}

.balance {
    font-size: 14px !important;
    line-height: 16px !important;
    font-family: 'GTWalsheimPro', sans-serif !important;
    color: $colorCloudyBlueTwo;
}

.is-empty {
    transition: all 0.2s ease-in-out;
    opacity: 0.3;
    background-color: $colorBlueGrey;
}
