@import '~assets/scss/variables.scss';

.root {
    color: $colorBlueGrey;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
    i {
        font-size: 11px;
        margin-right: 8px;
    }
}
