@import '~assets/scss/variables.scss';

.root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 40px;
}

.title-section {
}

.title {
    margin-bottom: 10px;
}

.last-login {
    font-size: 14px;
    color: $colorBlueGrey;
}

.buttons-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}
