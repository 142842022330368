@import '~assets/scss/variables.scss';

.root {
    width: 100%;
    background-color: $colorPaleGrey;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
    transition: all 300ms ease-out;
    &.is-open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        .dropdown {
            visibility: visible;
            opacity: 1;
            top: 100%;
        }
        .active {
            i {
                transform: rotate(270deg);
                color: $immoPrimary;
            }
        }
    }
}

.active {
    height: 40px;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    align-items: center;
    span {
        opacity: 0.6;
    }
    i {
        transition: all 300ms ease-out;
        transform: rotate(90deg);
        font-size: 11px;
    }
    &.have-active {
        span {
            opacity: 1;
        }
    }
}

.dropdown {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    padding: 16px;
    box-sizing: border-box;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    top: 90%;
    left: 0;
    width: 100%;
    background-color: $colorPaleGrey;
    transition: all 300ms ease-out;
    z-index: 10;
    max-height: 200px;
    overflow: auto;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    li {
        font-size: 14px;
        font-weight: 500;
        margin-top: 8px;
        display: flex;
        align-items: center;
        transition: all 300ms ease-out;
        img {
            width: 72px;
            height: 48px;
            object-fit: cover;
            border-radius: 8px;
            margin-right: 16px;
        }
        &:first-child {
            margin-top: 0;
        }
        &:hover {
            color: $immoPrimary;
        }
    }
}
