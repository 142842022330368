@import '~assets/scss/variables.scss';
@import '~assets/scss/shared.scss';

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease-out;
    opacity: 0;
    visibility: hidden;
    height: 100vh;
    overflow-y: auto;
    flex-wrap: wrap;
    &.is-visible {
        visibility: visible;
        opacity: 1;
    }
}

.modal {
    background-color: #fff;
    position: relative;
    opacity: 0;
    transition: all 0.4s ease-in-out;
    transform: scale(0.95);
    visibility: hidden;
    width: 100%;
    margin: 40px;
    padding: 32px;
    border-radius: 10px;
    font-family: Manrope, Helvetica, sans-serif;
    &.is-open {
        opacity: 1;
        visibility: visible;
        transform: scale(1);
    }
}

.close {
    width: 10px;
    height: 10px;
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 16px;
    padding: 8px;
    z-index: 5;
    box-sizing: border-box;
    &:after,
    &:before {
        content: '';
        width: 10px;
        height: 2px;
        border-radius: 4px;
        position: absolute;
        background-color: $colorBlueGrey;
        @extend %shared-hover-transition;
    }
    &:after {
        top: 7px;
        right: 3px;
        transform: rotate(45deg);
    }
    &:before {
        bottom: 7px;
        left: 3px;
        transform: rotate(-45deg);
    }
    &:hover {
        &:after,
        &:before {
            background-color: $colorWatermelon;
        }
    }
}
