@import '~assets/scss/variables.scss';

.root {
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    position: relative;
    img {
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        height: 190px;
        width: 100%;
        object-fit: cover;
    }
}

.label {
    position: absolute;
    color: #fff;
    top: 16px;
    left: 16px;
    background-color: $colorBlueGrey;
    padding: 9px 12px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
}

.body {
    padding: 13px 16px;
    box-sizing: border-box;
    h3 {
        font-size: 16px;
        line-height: 24px;
    }
    p {
        font-size: 14px;
        opacity: 0.5;
        margin-top: 2px;
    }
}

.options {
    position: absolute;
    top: 16px;
    right: 16px;
    padding-bottom: 20px;
    cursor: pointer;
    &:hover {
        .options-icon {
            background-color: #000;
        }
        .dot {
            background-color: #fff;
        }
        .dropdown {
            top: 44px;
            opacity: 1;
            visibility: visible;
        }
    }
}

.options-icon {
    background-color: #fff;
    width: 16px;
    height: 32px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 300ms ease-out;
}

.dot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: $colorBlueGrey;
    margin-top: 2px;
    transition: all 300ms ease-out;
    &:first-child {
        margin-top: 0;
    }
}

.button {
    margin-top: 14px;
    font-size: 14px;
}

.dropdown {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 35px;
    right: -25px;
    width: 139px;
    background-color: #fff;
    font-size: 12px;
    line-height: 16px;
    box-sizing: border-box;
    padding: 16px;
    border-radius: 8px;
    transition: all 300ms ease-out;
    &:before {
        content: '';
        position: absolute;
        top: -8px;
        right: 25px;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #fff;
    }
    li {
        margin-top: 8px;
        transition: all 300ms ease-out;
        &:first-child {
            margin-top: 0;
        }
        &:hover {
            color: $immoPrimary;
        }
    }
}
