@import '~assets/scss/variables.scss';

.root {
    font-size: 12px;
    border-radius: 8px;
    font-weight: 700;
    width: 100%;
    outline: none;
    border: none;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
        position: relative;
        z-index: 10;
    }
    &:before {
        content: '';
        position: absolute;
        width: 0;
        top: 0;
        bottom: 0;
        border-radius: 8px;
        left: 0;
        transition: width 240ms ease-out;
        background-color: rgba(0, 0, 0, 0.045);
    }
    &.is-small {
        padding: 12px;
        .progress-icon {
            width: 18px;
            height: 18px;
        }
    }
    &.is-medium {
        padding: 16px;
        font-size: 14px;
        .progress-icon {
            width: 23px;
            height: 23px;
        }
    }
    &.is-primary,
    &.is-red,
    &.is-immo,
    &.is-immo-white,
    &.is-immo-bordered,
    &.is-transparent {
        &:hover {
            &:before {
                width: 100%;
            }
        }
    }
    &.is-primary {
        background-color: $colorLightishBlue;
        color: #fff;
    }
    &.is-red {
        color: #fff;
        background-color: $colorWatermelon;
    }
    &.is-transparent {
        background-color: transparent;
        color: $colorLightishBlue;
    }
    &.is-immo-white {
        background-color: #fff;
        font-size: 14px;
        color: $colorCharcoalGrey;
        font-family: 'GTWalsheimPro', sans-serif;
    }
    &.is-immo {
        background-color: $immoPrimary;
        font-size: 14px;
        color: #fff;
        font-family: 'GTWalsheimPro', sans-serif;
    }
    &.is-immo-bordered {
        background-color: transparent;
        border: 1px solid #000;
        color: #000;
        font-size: 14px;
        font-family: 'GTWalsheimPro', sans-serif;
    }
    &.is-disabled {
        cursor: not-allowed;
        opacity: 0.4;
        &:before {
            display: none;
        }
    }
    &.in-progress {
        cursor: not-allowed;
        &:before {
            display: none;
        }
    }
}
