.selectWrapper {
    max-width: 300px;
    margin-right: 20px;
    width: 100%;
    position: relative;
}
.selects {
    display: flex;
}
.loader {
    position: absolute;
    bottom: 16px;
    width: 100%;
}
