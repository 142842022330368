@import '~assets/scss/variables.scss';

.root {
    width: 100px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dot {
    width: 11px;
    height: 11px;
    margin: 0 3px;
    border-radius: 50%;
    background-color: $colorWatermelon;
    &:nth-child(1) {
        animation: dotElastic 1s infinite linear;
    }
    &:nth-child(2) {
        animation: dotElasticBefore 1s infinite linear;
    }
    &:nth-child(3) {
        animation: dotElasticAfter 1s infinite linear;
    }
}

@keyframes dotElasticBefore {
    0% {
        transform: scale(1, 1);
    }
    25% {
        transform: scale(1, 1.5);
    }
    50% {
        transform: scale(1, 0.67);
    }
    75% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(1, 1);
    }
}

@keyframes dotElastic {
    0% {
        transform: scale(1, 1);
    }
    25% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1, 1.5);
    }
    75% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(1, 1);
    }
}

@keyframes dotElasticAfter {
    0% {
        transform: scale(1, 1);
    }
    25% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1, 0.67);
    }
    75% {
        transform: scale(1, 1.5);
    }
    100% {
        transform: scale(1, 1);
    }
}
