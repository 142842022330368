@import '~assets/scss/variables.scss';
@import '~assets/scss/shared.scss';

.root {
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
        color: $colorCharcoalGrey;
        font-size: 14px;
        white-space: nowrap;
    }
}

.input {
    width: 0;
    height: 0;
    overflow: hidden;
    opacity: 0;
    margin: 0;
    padding: 0;
    border: none;
}

.input:checked + .radio {
    &:after {
        opacity: 1;
        visibility: visible;
    }
}

.radio {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.is-white {
        background-color: #fff;
    }
    &.is-gray {
        background-color: #e8e9ef;
    }
    &:after {
        content: '';
        opacity: 0;
        visibility: hidden;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: $immoPrimary;
        transition: all 300ms ease-out;
    }
}
