$colorCharcoalGrey: #2b303a;
$colorBlueGrey: #9da7b7;
$colorCloudyBlueTwo: #b6c3d9;
$colorPaleGrey: #f5f6fb;
$colorRoot: #000;
$colorLightishBlue: #4b67f8;
$colorWatermelon: #f85563;
$grey: #ecf2fd;
$lightGreen: #3bb272;
$lightRed: #ff525c;
$colorOrange: #efa540;
$darkerOrange: #fd9d40;

$immoPrimary: #1ca8ff;
$immoRed: #ff525c;
$immoGreen: #5acb84;

$breakpoints: (
    'mobile': 800px,
    'tablet': 1100px,
) !default;

$regularFont: 'GT Walsheim Pro Regular', sans-serif;
$boldFont: 'GT Walsheim Pro Bold', sans-serif;
