@import '~assets/scss/shared.scss';
@import '~assets/scss/variables.scss';

.root {
    font-size: 16px;
    width: 100%;
    outline: none;
    border-radius: 8px;
    border: none;
    padding: 12px 20px;
    font-family: GTWalsheimPro;
    font-size: 14px;
    line-height: 14px;
    box-sizing: border-box;
    background-color: $colorPaleGrey;
    resize: none;
}
