.root {
    position: relative;
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    &.is-left {
        justify-content: flex-start;
    }
    &.is-right {
        justify-content: flex-end;
    }
    &.is-center {
        justify-content: center;
    }
    &.is-active {
        .dropdown {
            visibility: visible;
            opacity: 1;
            transform: translateY(0);
        }
    }
}

.label {
    display: flex;
    align-items: center;
}

.dropdown {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-10px);
    transition: all 300ms ease-in-out;
}
