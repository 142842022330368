.table :global {
    table {
        table-layout: fixed;
    }
    .table-th {
        &-subject {
            width: 20%;
        }
        &-id,
        &-state {
            width: 12%;
        }
    }
    .table-td {
        &-body {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 5%;
        }
    }
}
