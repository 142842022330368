@import '~assets/scss/shared.scss';
@import '~assets/scss/variables.scss';

.block-item {
    width: 100%;
    height: 100%;
    padding: 28px 32px;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    @extend %shared-hover-transition;

    &:hover {
        box-shadow: 1px 1px 17px 4px rgba(0, 0, 0, 0.05);
        .icon-wrapper {
            background-color: $colorLightishBlue;
        }
    }

    &.is-disabled {
        opacity: 0.4;
        .body {
            cursor: default;
            .icon-wrapper {
                background-color: $colorBlueGrey !important;
            }
        }
    }
}

.image-wrapper {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $colorPaleGrey;
}

.text-wrapper {
    flex: 1;
}

.description {
    color: $colorCloudyBlueTwo;
    font-weight: 500;
    margin-top: 10px;
    font-size: 14px;
}

.icon-wrapper {
    min-width: 32px;
    min-height: 32px;
    background-color: $colorBlueGrey;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    @extend %shared-hover-transition;
    i {
        font-size: 9px;
        color: #fff;
    }
}
