$icons-font: "icons";

@font-face {
    font-family: $icons-font;
    src: url("/assets/fonts/Icons/icons.eot?bbfe384068d1bdcef1d206d9da483e27#iefix") format("embedded-opentype"),
url("/assets/fonts/Icons/icons.woff2?bbfe384068d1bdcef1d206d9da483e27") format("woff2"),
url("/assets/fonts/Icons/icons.woff?bbfe384068d1bdcef1d206d9da483e27") format("woff");
}

i[class^="icon-"]:before, i[class*=" icon-"]:before {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$icons-map: (
    "analytic": "\f101",
    "arrow-left": "\f102",
    "arrow-right": "\f103",
    "auction": "\f104",
    "cart": "\f105",
    "checkbox": "\f106",
    "cheque": "\f107",
    "close": "\f108",
    "dropdown-arrow": "\f109",
    "dropdown": "\f10a",
    "edit": "\f10b",
    "filter": "\f10c",
    "financial": "\f10d",
    "objects": "\f10e",
    "overview": "\f10f",
    "pdf": "\f110",
    "search": "\f111",
    "settings": "\f112",
    "time": "\f113",
);

.icon-analytic:before {
    content: map-get($icons-map, "analytic");
}
.icon-arrow-left:before {
    content: map-get($icons-map, "arrow-left");
}
.icon-arrow-right:before {
    content: map-get($icons-map, "arrow-right");
}
.icon-auction:before {
    content: map-get($icons-map, "auction");
}
.icon-cart:before {
    content: map-get($icons-map, "cart");
}
.icon-checkbox:before {
    content: map-get($icons-map, "checkbox");
}
.icon-cheque:before {
    content: map-get($icons-map, "cheque");
}
.icon-close:before {
    content: map-get($icons-map, "close");
}
.icon-dropdown-arrow:before {
    content: map-get($icons-map, "dropdown-arrow");
}
.icon-dropdown:before {
    content: map-get($icons-map, "dropdown");
}
.icon-edit:before {
    content: map-get($icons-map, "edit");
}
.icon-filter:before {
    content: map-get($icons-map, "filter");
}
.icon-financial:before {
    content: map-get($icons-map, "financial");
}
.icon-objects:before {
    content: map-get($icons-map, "objects");
}
.icon-overview:before {
    content: map-get($icons-map, "overview");
}
.icon-pdf:before {
    content: map-get($icons-map, "pdf");
}
.icon-search:before {
    content: map-get($icons-map, "search");
}
.icon-settings:before {
    content: map-get($icons-map, "settings");
}
.icon-time:before {
    content: map-get($icons-map, "time");
}
