@import '~assets/scss/variables.scss';

.section {
    margin-top: 24px;
    display: flex;
    gap: 5px;
}

.bar {
    height: 8px;
    border-radius: 6px;
    transition: all 0.3s ease-in-out;
    &.is-blue {
        background-color: $colorLightishBlue;
    }
    &.is-red {
        background-color: $colorWatermelon;
    }
    &.is-orange {
        background-color: $darkerOrange;
    }
}
