@import '~assets/scss/variables.scss';

.root {
    margin-top: 4px;
    h4 {
        font-size: 20px !important;
        line-height: 28px !important;
    }
    p {
        margin-top: 5px;
        font-size: 14px;
        line-height: 16px;
        color: $colorBlueGrey;
    }
}

.list {
    margin-top: 22px;
    display: flex;
}

.loader {
    width: 128px;
    height: 128px;
}

.image-wrapper {
    position: relative;
    margin-left: 16px;
    &:hover {
        .make-primary {
            opacity: 1;
            visibility: visible;
        }
    }
}

.remove {
    z-index: 10;
    cursor: pointer;
    position: absolute;
    top: 8px;
    right: 8px;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 9px;
    background-color: #fff;
    transition: all 300ms ease-out;
    &:hover {
        color: red;
    }
}

.make-primary {
    width: 90px;
    height: 24px;
    color: #fff;
    background-color: $immoPrimary;
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -12px;
    margin-left: -45px;
    font-size: 12px;
    cursor: pointer;
    border-radius: 8px;
    opacity: 0;
    visibility: hidden;
    transition: all 300ms ease-out;
}

.primary {
    font-size: 12px;
    padding: 6px 8px;
    border-radius: 8px;
    position: absolute;
    top: 9px;
    left: 8px;
    background-color: #000;
    color: #fff;
}

.image {
    display: block;
    width: 128px;
    height: 128px;
    object-fit: cover;
    border-radius: 8px;
}

.icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $immoPrimary;
    i {
        font-size: 12px;
        color: #fff;
        transform: rotate(-45deg);
    }
}
