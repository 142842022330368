@import '~assets/scss/variables.scss';

.card {
    position: relative;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
}

.titleWrap {
    display: flex;
    margin-bottom: 32px;
    justify-content: space-between;
    align-items: center;
}

.legend {
    gap: 12px !important;
}

.chart {
    height: 400px;
    padding-bottom: 40px;
    padding-left: 40px;
}

.select.select {
    width: 120px;

    :global {
        .select__control {
            background-color: #f5f6fb;
        }
    }
}

.is-empty {
    transition: all 0.2s ease-in-out;
    opacity: 0.3;
    background-color: $colorBlueGrey;
}

.empty-label {
    margin: auto;
    z-index: 100;
}

.label {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background-color: $colorPaleGrey;
    font-size: 12px;
    line-height: 12px;
    &.is-bottom {
        bottom: 32px;
        right: 32px;
        left: 105px;
        height: 24px;
    }
    &.is-left {
        top: 92px;
        left: 32px;
        width: 24px;
        bottom: 96px;
    }
    span {
        transform: rotate(-90deg);
        min-width: 200px;
        text-align: center;
    }
}
