@import '~assets/scss/variables.scss';

.button {
    position: relative;
    min-height: 75px;
    border-radius: 10px;
    background-color: white;
    display: flex;
    align-items: center;
    padding-left: 32px;
    padding-right: 24px;
    text-decoration: none;
    color: $colorRoot;
    cursor: pointer;
    &::before {
        content: '';
        position: absolute;
        left: 8px;
        top: 8px;
        bottom: 8px;
        width: 4px;
        border-radius: 6px;
        background-color: $grey;
    }
    &.is-red {
        .number {
            color: $lightRed;
        }
        &::before {
            background-color: $lightRed;
        }
    }
    &:hover {
        .icon {
            color: $colorLightishBlue;
        }
        .arrow {
            background-color: $colorLightishBlue;
        }
    }
    a {
        text-decoration: none !important;
    }
}

.label {
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    margin-left: 20px;
}

.icon {
    transition: all 0.3s ease-in-out;
    color: $colorBlueGrey;
    font-size: 20px;
}

.number {
    color: $colorBlueGrey;
}

.arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $colorBlueGrey;
    color: white;
    min-width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-left: auto;
    transition: all 0.3s ease-in-out;
    font-size: 10px;
}
