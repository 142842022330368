@import '~assets/scss/variables.scss';

.card {
    // height: 170;
    min-height: 150px;
    background-color: white;
    border-radius: 10px;
    padding: 24px 24px 24px 32px;
}
.status {
    display: grid;
    grid-template-columns: 1fr 80px;
}
.title {
    position: relative;
    white-space: nowrap;
    overflow: visible;
    margin-top: 8px;
    margin-bottom: 32px;
}
.total {
    font-size: 32px;
    line-height: 32px;
    font-family: 'GTWalsheimPro', sans-serif;
    font-weight: 700;
    &:not(:last-child) {
        margin-bottom: 10px;
    }
}

.balance {
    font-size: 14px !important;
    line-height: 16px !important;
    font-family: 'GTWalsheimPro', sans-serif !important;
    color: $colorCloudyBlueTwo;
    // white-space: nowrap;
    // overflow: visible;
    .is-positive {
        color: $lightGreen;
    }
    .is-negative {
        color: $lightRed;
    }
    // &.refund {
    //     text-indent: 10px;
    // }
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: $colorPaleGrey;
}

.hidden {
    opacity: 0;
}
