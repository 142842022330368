.legend {
    display: flex;
    align-items: center;
    gap: 32px;
}

.item {
    display: flex;
    align-items: center;
}

.dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 12px;
    &.is-blue {
        background-color: #4b67f8;
    }
    &.is-red {
        background-color: #f85563;
    }
    &.is-orange {
        background-color: #fd9d40;
    }
}

.label {
    font-family: 'GT Walsheim Pro Bold', sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
}
