@import "variables";

@mixin font-face($font-name, $path, $weight: normal, $style: normal) {
  @font-face {
    font-family: quote($font-name);
    src: url($path + ".eot");
    src: url($path + ".eot?#iefix") format("embedded-opentype"), url($path + ".woff") format("woff"),
      url($path + ".ttf") format("truetype"), url($path + ".svg##{$font-name}") format("svg");
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@mixin breakpoint($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}
