.subtitle {
    font-weight: bold;
    margin-bottom: 8px;
}
.info {
    color: #9da6b7;
    margin-bottom: 24px;
}
.graphs {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}
