@import '~assets/scss/variables.scss';

.cancel-button {
    width: 200px;
    background-color: rgba(#000000, 0.04) !important;
    z-index: 1;
}

.comment-section {
    margin: 32px auto 16px;
    width: 100%;
}

.label {
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 8px;
}

.textarea {
    font-size: 14px;
    line-height: 14px;
    width: 100%;
    height: 164px;
    background: #f5f6fb;
    border-radius: 8px;
    resize: none;
    border: none;
    font-family: 'GT Walsheim Pro Regular', sans-serif !important;
    padding: 13px 20px;
}

.plan-modal {
    font-family: 'GT Walsheim Pro Regular', sans-serif;
    text-align: center;
}

.title {
    font-size: 16px;
    line-height: 16px;
    font-family: 'GT Walsheim Pro Bold', sans-serif;
    font-weight: 700;
    margin-bottom: 24px;
}

.subtitle {
    font-size: 16px;
    line-height: 24px;
    margin-top: 24px;
    margin-bottom: 24px;
}

.image {
    width: 108px;
    height: 108px;
    margin-bottom: 29px;
}

.jp {
    width: 212px;
    height: 234px;
}

.items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: left;
    row-gap: 22px;
    margin-bottom: 40px;
}
.item {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 14px;
    .check {
        font-size: 12px;
        color: white;
        background-color: $colorLightishBlue;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
        padding-left: 2px;
    }
}

.button {
    max-width: 120px;
    font-size: 12px;
    line-height: 12px;
    margin: 0 auto;
}

.buttons {
    max-width: 320px;
    margin: 32px auto 0;
}

.contact-button {
    background-color: transparent !important;
    color: #000000 !important;
    margin-bottom: 16px;
    border: 2px solid #000000;
    span {
        display: flex !important;
        align-items: center !important;
        img {
            margin-right: 8px;
        }
    }
}
