@import '~assets/scss/variables.scss';

.checkbox {
    display: flex;
    align-items: center;
    color: $colorCharcoalGrey;
    .box {
        width: 16px;
        height: 16px;
        border-radius: 4px;
        background-color: #fff;
        display: inline-block;
        border: 1px solid #c1cbde;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 150ms ease-in;

        i {
            font-size: 8px;
            margin-left: 1px;
            color: #fff;
        }
    }
    .label {
        font-family: $regularFont;
    }
    &.checked .box {
        background-color: $colorLightishBlue;
        border-color: $colorLightishBlue;
    }
    &.checked .label {
        font-family: $boldFont;
    }
}
