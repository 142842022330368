.root {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.title,
.text {
    text-align: center;
}

.title {
    max-width: 435px;
    font-size: 16px !important;
    line-height: 16px !important;
}

.icon {
    margin-top: 24px;
    margin-bottom: 4px;
    height: 80px;
    width: 80px;
}

.text {
    margin-top: 20px;
}

.buttons {
    display: flex;
    margin-top: 24px;
    justify-content: center;
    button {
        margin: 0 5px;
        width: 143px;
    }
}

.button {
    width: 164px !important;
}
