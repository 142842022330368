@import '~assets/scss/variables.scss';

.root {
    position: relative;
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
}

.input {
    display: none;
}

.slider {
    width: 44px;
    height: 24px;
    border-radius: 32px;
    cursor: pointer;
    transition: 0.4s;
    background-color: #f5f6fb;
    position: relative;
    margin-right: 10px;
}

.slider:before {
    background-color: $immoPrimary;
    bottom: 4px;
    content: '';
    height: 16px;
    left: 4px;
    position: absolute;
    transition: 0.4s;
    width: 16px;
    border-radius: 50%;
}

.input:checked + .slider {
    background-color: $immoPrimary;
    &:before {
        background-color: #fff;
    }
}

.input:checked + .slider:before {
    transform: translateX(19px);
}
