@import '~assets/scss/variables.scss';

.root {
    margin-top: 33px;
    ul {
        display: flex;
        li {
            position: relative;
            font-size: 20px;
            font-weight: 700;
            color: $colorBlueGrey;
            margin-left: 49px;
            cursor: pointer;
            span {
                position: absolute;
                top: 0;
                left: 100%;
                padding-left: 3px;
                width: 22px;
                font-size: 12px;
            }
            &.is-empty {
                span {
                    color: $colorBlueGrey !important;
                }
            }
            &.is-active {
                color: #000;
                cursor: default;
                span {
                    color: #f2a844;
                }
            }
            &:first-child {
                margin-left: 0;
                &.is-active {
                    span {
                        color: #5acb84;
                    }
                }
            }
        }
    }
}
