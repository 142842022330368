@import '~assets/scss/variables.scss';

.root {
    padding: 10px;
    position: relative;
    cursor: pointer;

    i {
        font-size: 7px;
        transition: all 300ms ease-out;
    }

    &:hover {
        .dropdown {
            top: 25px;
            opacity: 1;
            visibility: visible;
        }
        i {
            color: $immoPrimary;
            transform: rotate(-180deg);
        }
    }
}

.wrapper {
    display: flex;
    gap: 10px;
}

.language {
    text-transform: capitalize;
}

.dropdown {
    z-index: 1;
    position: absolute;
    top: 15px;
    right: -25px;
    opacity: 0;
    visibility: hidden;
    padding-top: 20px;
    transition: all 300ms ease-out;

    li {
        margin-top: 12px;
        transition: all 300ms ease-out;
        &:first-child {
            margin-top: 0;
        }
        &:hover {
            color: $immoPrimary;
        }
    }
}

.dropdown-body {
    padding: 18px 19px;
    box-sizing: border-box;
    background-color: #fff;
    font-size: 12px;
    font-weight: 400;
    border-radius: 8px;
    &:before {
        content: '';
        position: absolute;
        top: 13px;
        right: 22px;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #fff;
    }
}
