@import '~assets/scss/variables.scss';

.root {
    border: 1px dashed $colorBlueGrey;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 10px;
    box-sizing: border-box;
    &.have-error {
        border-color: #ff4c4c;
    }
}

.input {
    display: none;
}
