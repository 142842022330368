@import "~assets/scss/variables.scss";

.root {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 23px;
  background-color: $colorPaleGrey;
  border-radius: 15px;
  cursor: pointer;
}

.input {
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  &:checked + .slider {
    transform: translateX(26px);
    background-color: $colorLightishBlue;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 24px;
  height: 24px;
  transition: 0.4s;
  border-radius: 50%;
  background-color: $colorBlueGrey;
}
