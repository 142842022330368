@import '~assets/scss/variables.scss';

.root {
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    &.is-disabled {
        cursor: default;
    }
}

.input {
    display: none;
}

.icon {
    position: relative;
    height: 26px;
    width: 100%;
    max-width: 26px;
    min-width: 26px;
    border: 2px solid #d0ddf2;
    border-radius: 4px;
    transition: background-color 150ms linear;

    &:before {
        content: '';
        position: absolute;
        border-bottom: #fff 2px solid;
        border-left: #fff 2px solid;
        opacity: 0;
        transition: opacity 150ms linear;
        width: 14px;
        height: 7px;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
    }
}

.input {
    &:checked {
        + .icon {
            border-color: $immoPrimary;
            background-color: $immoPrimary;

            &::before {
                opacity: 1;
            }
        }
    }
}

.label {
    padding-left: 11px;
    font-size: 16px;
}
