@import '~assets/scss/variables.scss';

.filter {
    font-size: 12px;
    position: relative;
    i {
        font-size: 16px;
        margin-left: 6px;
    }

    &:hover {
        cursor: pointer;
    }

    .toggler {
        display: flex;
    }

    .dropdownWrapper {
        transition: all 150ms ease-in;
        max-height: 0;
        overflow: hidden;
        position: absolute;
        top: 26px;
        z-index: 10;
        opacity: 0;
        &.toggled {
            max-height: 300px;
            opacity: 1;
        }
    }

    .filterDropdown {
        width: 136px;
        padding: 12px 16px;
        background: #fff;
        border: 1px solid #c1cbde;
        border-radius: 8px;
        > div:not(:last-child) {
            margin-bottom: 16px;
        }
    }
}
