@import '~assets/scss/variables';

.sortArrows {
    i {
        font-size: 6px;
        display: inline-block;
        margin-left: 8px;
        color: $colorBlueGrey;
    }
    .up {
        transform: rotate(180deg);
        vertical-align: text-top;
    }
    .active {
        color: $colorLightishBlue;
    }

    .initialState {
        display: inline-block;
        vertical-align: middle;
        margin-left: 8px;
        opacity: 0.6;
        i {
            display: block;
            margin: 0;
        }
        i:first-child {
            margin: 0 0 0 -1px;
        }
    }
}
.activeCol {
    color: $colorLightishBlue;
}
