@import '~assets/scss/index.scss';
@import '~react-date-range/dist/styles.css';
@import '~react-date-range/dist/theme/default.css';

html {
    -moz-osx-font-smoothing: grayscale;
    // -webkit-font-smoothing: antialiased;
}

body {
    font-family: 'GTWalsheimPro', sans-serif;
    background-color: rgb(245, 246, 251);

    @-moz-document url-prefix() {
        font-weight: lighter !important;
    }
}
