@import '~assets/scss/variables.scss';

.card {
    min-height: 380px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.title {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;
    margin-bottom: 32px;
}

.chart {
    height: 360px;
}

.is-empty {
    transition: all 0.2s ease-in-out;
    opacity: 0.3;
    background-color: $colorBlueGrey;
}

.empty-label {
    margin: auto;
    z-index: 100;
}
